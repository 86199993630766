header{
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container{
    height: 100%;
    text-align: center;
    position: relative;
}

/*************************FOR CALL TO ACTION BUTTON*********************************/
.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}


/*************************FOR HEADERR SOCIAL BUTTON*********************************/
.header__social{
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    gap: 0.8rem;
    left: 0;
    bottom: 3rem;
}

.header__social::after{
    content: '';
    width: 1px;
    height: 3rem;
    background: var(--color-primary);
}


/*************************FOR HEADERR DISPLAY PICTURE*********************************/
.me{
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
    overflow: hidden;
}

/*************************FOR HEADERR SCROLL TEXT*********************************/
.scroll__down{
    position: absolute;
    right: -2.5rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/**************************** MEDIA QUERIES (MEDIUM DEVICES) **************************************/

@media screen and (max-width : 1024px) {
    header{
        height: 68vh;
    }
}

/**************************** MEDIA QUERIES (SMALL DEVICES) **************************************/

@media screen and (max-width : 600px) {
    header{
        height: 100vh;
    }

    .header__social, .scroll__down {
        display: none;
    }
}