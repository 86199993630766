.testimonials__container{
    width: 40%;
    padding-bottom: 4rem;
}
.swiper-pagination-clickable .swiper-pagination-bullet{
    background: var(--color-primary);
}
.testimonial__card{
    background: var(--color-bg-variant);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
    height: 20rem;
}

.client__avatar{
    width: 4rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    margin: 0 auto 1rem;
    border-radius: 50%;
    border: 0.4rem solid var(--color-pramary-variant)
}


.client__review{
    color:var(--color-light);
    font-weight: 300;
    display: block;
    width: 80%;
    margin: 0.8rem auto 0;
}

/**************************** MEDIA QUERIES (MEDIUM DEVICES) **************************************/

@media screen and (max-width : 1024px) {
    .testimonials__container{
        width: 60%;
    }
  }
  
  /**************************** MEDIA QUERIES (SMALL DEVICES) **************************************/
  
  @media screen and (max-width : 600px) {
    .testimonials__container{
        width: var(--container-width-sm);
        
    }
    .client__review{
        width: var(--container-width-sm);
    }
    .testimonial__card{
        height: 25rem;
    }
  }
